declare let $2sxc: any;
declare let Cookies: any;

import { UiActions } from './add-ins/uiActions';
import { CollectFieldsManual } from './collect-fields/manual';
import { CollectFieldsAutomatic } from './collect-fields/auto';
import { Recaptcha } from './add-ins/recaptcha';

export class App {
  helperFunc = new UiActions();
  collectFieldsManual = new CollectFieldsManual();
  collectFieldsAutomatic = new CollectFieldsAutomatic();
  recaptcha = new Recaptcha();
  moduleWrapper: JQuery;
  alreadyInit = false;
  form: string;
  moduleId: number;
  sxc: any;

  c = {
    clsWrp: 'mobius-wrapper',
    clsForm: 'mobius-form',
  };

  constructor(
    moduleId: number,
    form: string,
  ) {
    // disable validate on the global asp.net form, to not interfere with the contact-form
    $('form').attr('novalidate', '');

    this.moduleWrapper = $(`.DnnModule-${moduleId}-${form}`);
    this.moduleId = moduleId;
    this.form = form;
    this.sxc = $2sxc(moduleId);
  }

  public initialize() {
    const wrapper = this.moduleWrapper;

    // attach validation to enable as soon as we blur
    if (this.form == 'registration') {
      wrapper.on('blur', ':input', this.helperFunc.attachFieldValidateOnBlur);
    }

    wrapper.each((i, item) => {
      // prevent dupl execution
      if (this.alreadyInit) {
        return;
      }

      const wrap = $(item);
      wrap.find('#sendFormWithApi' + this.form).on('click', (evt: JQueryEventObject) => this.send(evt)); // handle click event

      this.alreadyInit = true;
    });
  }

  public send(event: JQueryEventObject) {
    const wrapper = this.moduleWrapper;
    const label = wrapper.find('#sendFormWithApi' + this.form).text();

    // clear all alerts
    this.helperFunc.showOneAlert(wrapper, '');

    const trackingEvent = new CustomEvent('trackMobiusForm', { detail: { category: 'mobius-form', action: 'submit', label: label } });
    document.dispatchEvent(trackingEvent);

    // Validate form
    if (this.form == 'registration') {
      if (!(wrapper as any).smkValidate())
        return this.helperFunc.showOneAlert(wrapper, 'msgIncomplete');
    }

    // Do Recaptcha test, show alert & fail if required and not complete
    const recap = this.recaptcha.check(wrapper);
    if (!recap)
      return this.helperFunc.showOneAlert(wrapper, 'msgRecap');

    const mailchimp = wrapper.find('.mobius-wrapper').hasClass('mobius-mailchimp');

    // get data
    // alternative example with manual build, but we prefer automatic
    // let data;
    // data = this.collectFieldsManual.collect(wrapper);
    this.collectFieldsAutomatic.collect(wrapper).then((data: any) => {
      const ws = wrapper.find('.mobius-wrapper').data('webservice'); // should be "Form/ProcessForm" or a custom override
      data.Recaptcha = recap;
      data.MailChimp = mailchimp;

      // submission
      this.helperFunc.disableInputs(wrapper, true);
      this.helperFunc.showOneAlert(wrapper, 'msgSending'); // show "sending..."

      if (this.form == 'registration') {
        this.sxc.webApi.post(ws, {}, data, true)
          .success(() => {
            this.helperFunc.showOneAlert(wrapper, 'msgNewsletterSuccess');

            const trackingEvent = new CustomEvent('trackMobiusForm', { detail: { category: 'mobius-form', action: 'success', label: label } });
            document.dispatchEvent(trackingEvent);

            // Set cookie to disable content protection
            Cookies.set('contentProtection', 'Off', { expires: 7 });
            location.reload();
          })
          .error(() => {
            this.helperFunc.showOneAlert(wrapper, 'msgNewsletterFailed');
            this.helperFunc.disableInputs(wrapper, false);

            const trackingEvent = new CustomEvent('trackMobiusForm', { detail: { category: 'mobius-form', action: 'error', label: label } });
            document.dispatchEvent(trackingEvent);
          });
      } else {
        this.sxc.webApi.post(ws, {}, data, true)
          .success((token: { access_token: string, expires_in: string, token_type: number }) => {
            this.helperFunc.showOneAlert(wrapper, 'msgOk');

            // Set access token cookie to disable content protection
            Cookies.set('ExtranetAccessToken', token.access_token, { expires: 7 })

            const trackingEvent = new CustomEvent('trackMobiusForm', { detail: { category: 'mobius-form', action: 'success', label: label } });
            document.dispatchEvent(trackingEvent);

            // set local storage values required for user-menu and extranet
            localStorage.setItem("ls.ExtranetUserName", data.email);
            this.sxc.webApi.get('app/SpeedgoatExtranet/api/Profile/GetUserProfile')
              .success((userProfile: any) => {
                userProfile.Initials = userProfile.FirstName.substr(0, 1) + userProfile.LastName.substr(0, 1);
                localStorage.setItem("ls.ExtranetUser", JSON.stringify(userProfile));

                location.reload();
              })
          })
          .error(() => {
            this.helperFunc.showOneAlert(wrapper, 'msgError');
            this.helperFunc.disableInputs(wrapper, false);

            const trackingEvent = new CustomEvent('trackMobiusForm', { detail: { category: 'mobius-form', action: 'error', label: label } });
            document.dispatchEvent(trackingEvent);
          });
      }


    });
  }
}